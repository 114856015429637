var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// @ts-nocheck
import React from 'react';
import { Box, Text } from 'elemental-react';
var Code = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (<Box borderRadius={3} bg="rgba(27,31,35,.1)" pr={2} pl={2} pt={1} pb={1} alignItems="center" {...props}>
    <Text name="Swatch Name" fontSize={18}>
      <Text color="#000" fontFamily="Courier">
        {children}
      </Text>
    </Text>
  </Box>);
};
export default Code;
