// @ts-nocheck
import React from 'react';
import { Box, Headline, Text, Line } from 'elemental-react';
;
var Section = function (_a) {
    var title = _a.title, titleColor = _a.titleColor, description = _a.description, children = _a.children;
    return (<Box>
    <Box px={40} pt={40}>
      <Headline.H2 bold fontSize={48} lineHeight={64} color={titleColor}>
        {title}
      </Headline.H2>
      {Boolean(description) && (<Text fontSize={20} lineHeight={24} color="#6E7379" mt={12}>
          {description}
        </Text>)}
      <Line mt={16} bg="#D8D8D8" width="100%"/>
    </Box>
    {children}
  </Box>);
};
export default Section;
