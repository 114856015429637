var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// @ts-nocheck
import React from 'react';
import { Box, Headline, Text, Row } from 'elemental-react';
import chroma from 'chroma-js';
// import { useTranslation } from 'react-i18next';
import Section from './components/Section';
import Code from './components/Code';
var textColor = function (hex) {
    var vsWhite = chroma.contrast(hex, 'white');
    if (vsWhite > 4) {
        return '#FFF';
    }
    return chroma(hex)
        .darken(3)
        .hex();
};
var Ampersand = function (_a) {
    var color = _a.color, props = __rest(_a, ["color"]);
    return <Text fontSize={42} fontFamily="Helvetica" color={textColor(color)} {...props}/>;
};
var SwatchTile = function (props) { return <Box {...props}/>; };
SwatchTile.defaultProps = {
    alignItems: 'center',
    justifyContent: 'center',
    height: 120,
    width: 120,
    bg: 'black',
};
;
var Swatch = function (_a) {
    var rounded = _a.rounded, id = _a.id, index = _a.index, name = _a.name, hex = _a.hex;
    return (<Box alignItems="center">
    <SwatchTile name={"Swatch " + name} bg={hex}>
      <Ampersand color={hex}>
        {chroma.contrast(hex, 'white') > 4 ? 'W' : 'B'}
      </Ampersand>
    </SwatchTile>
    <Text name="Color Shade" fontSize="h6" mt={3} mb={3}>
      {index === 0 ? '50' : index + "00"}
    </Text>
    <Text color="black" mb={3}>{hex}</Text>
    <Code>
      {id + "." + index}
    </Code>
  </Box>);
};
;
var Colors = function (_a) {
    var colors = _a.colors;
    // const { t } = useTranslation('colors');
    var t = function (n) { return n; };
    var colorFamilies = Object.entries(colors.families).map(function (_a) {
        var id = _a[0], val = _a[1];
        return ({ id: id, colors: val });
    })
        .filter(function (_a) {
        var id = _a.id;
        return !['blacks', 'whites'].includes(id);
    });
    var colorList = Object.entries(colors.colors).map(function (_a) {
        var id = _a[0], val = _a[1];
        return ({ id: id, color: val });
    });
    var keys = Object.entries(colors.keys).map(function (_a) {
        var id = _a[0], val = _a[1];
        var parentFamily = colorFamilies.find(function (_a) {
            var colorList = _a.colors;
            return colorList.includes(val);
        });
        var parentIndex = parentFamily ? parentFamily.colors.indexOf(val) : -1;
        return { id: id, color: val, parent: parentFamily ? { family: parentFamily.id, index: parentIndex } : undefined };
    });
    return (<Section title="Colors">
      <Box p={80}>
        <Headline.H4 mb={2}>
          Key Colors
        </Headline.H4>
        <Row flexWrap="wrap" mb={3}>
          {keys.map(function (_a) {
        var id = _a.id, color = _a.color, parent = _a.parent;
        return (<Box alignItems="center" key={id}>
              <SwatchTile name={"Swatch " + id} bg={color} m={4} borderRadius={100} {...((chroma.contrast('white', color) < 1) && { borderColor: 'black', borderWidth: 1 })}/>
              <Text fontFamily="Varela" fontSize={4} name="Color Shade" mb={3}>
                
                {id}
              </Text>
              {parent && (<Text name="Color Shade" fontSize={18} mb={3}>
                  
                  {t("" + parent.family) + " " + parent.index + "00"}
                </Text>)}
            </Box>);
    })}
        </Row>
        <Headline.H4 mb={2}>
          Colors
        </Headline.H4>
        <Row flexWrap="wrap" mb={3}>
          {colorList.map(function (_a) {
        var id = _a.id, color = _a.color;
        return (<Box alignItems="center" key={id}>
              <SwatchTile name={"Swatch " + id} bg={color} m={4} borderRadius={100} {...((chroma.contrast('white', color) < 1) && { borderColor: 'black', borderWidth: 1 })}/>
              <Text name="Color Shade" fontFamily="Varela" fontSize={4} mb={3}>
                
                {id + "s 500"}
              </Text>
            </Box>);
    })}
        </Row>
        <Headline.H4 mb={2}>
          
          Color Families
        </Headline.H4>
        {colorFamilies.map(function (_a) {
        var id = _a.id, colors = _a.colors;
        return (<Box mt={3} key={id}>
            <Headline.H5 mb={3}>
              
              {t("" + id)}
            </Headline.H5>
            <Row key={id}>
              {colors.map(function (color, i) { return (<Swatch id={id} index={i} name={id + "." + i} hex={color} key={color + "/" + i}/>); })}
            </Row>
          </Box>);
    })}
      </Box>
    </Section>);
};
export default Colors;
